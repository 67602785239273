/**
 *  This module is a simplified image manager for the case when
 *  multiple images are associated with the target model, and
 *  you wish to select one for a particular use
 *
 **/

import Modal from 'Modal';

export default class ImageSelector {
  constructor(config) {
    this.trigger = config.trigger;
    this.afterUpload = config.afterUpload;
    this.images_url = config.images_url;
    this.onSelect = config.onSelect;
    this.image_input = config.image_input;
    this.form = config.form;
    this.submit_button = config.submit_button;
    this.uploading = false;
  }

  init() {
    this._build();

    $.when(this._populateImages()).done(function() {
      this.modal = new Modal({
        popup_element: this.container,
        trigger_element: this.trigger,
        overlay: true,
        center: true,
        default_closed: true,
        close_button_class: 'modal_close_button'
      });

      this.modal.init();

      this._setupImageSelectListener();

      this._setupImageSizeSelectListener();

      this._setupUploadListener();

      this._setupToggleListeners();
    });
  }

  _build() {
    this.container = document.createElement('div');
    this.container.className = 'image-selector';
    document.body.appendChild(this.container);

    this.header = document.createElement('header');
    this.header.innerHTML = '<h1>Add Stimulus</h1>';

    this.container.appendChild(this.header);

    this.buttons_container = document.createElement('div');
    this.buttons_container.className = 'buttons-container';

    this.upload_tab = document.createElement('div');
    this.upload_tab.className = 'images-tab selected';
    this.upload_tab.innerHTML = 'Upload';

    this.buttons_container.appendChild(this.upload_tab);

    this.existing_tab = document.createElement('div');
    this.existing_tab.className = 'images-tab';
    this.existing_tab.innerHTML = 'Browse Uploaded';
    this.buttons_container.appendChild(this.existing_tab);

    this.container.appendChild(this.buttons_container);

    this.upload_container = document.createElement('div');
    this.upload_container.className = 'image-container';
    $(this.image_input).appendTo(this.upload_container);

    this.image_requirements = document.createElement('p');
    this.image_requirements.className = 'image-requirements';
    this.image_requirements.innerHTML = 'Image Requirement: .JPEG / .JPG / .PNG / .GIF';

    if ($(this.image_input).hasClass('check-file-size')) this.image_requirements.innerHTML += ' and less than 2MB';

    $(this.image_requirements).appendTo(this.upload_container);

    this.container.appendChild(this.upload_container);

    this.existing_container = document.createElement('div');
    this.existing_container.className = 'images_box existing-images';
    this.existing_container.style.overflowY = 'scroll';
    this.existing_container.style.display = 'none';
    this.existing_container.style.position = 'relative';

    this.container.appendChild(this.existing_container);

    this.footer = document.createElement('footer');

    const error_container = document.createElement('div');
    error_container.className = 'error-container red';
    this.footer.appendChild(error_container);

    $(this.submit_button).appendTo(this.footer);

    this.container.appendChild(this.footer);

    this.container.style.display = 'none';
  }

  _populateImages() {
    return $.ajax({
      context: this,
      url: this.images_url,
      type: 'GET',
      dataType: 'json'
    })
      .done(function(data) {
        this.image_data = data;
        this._appendImages();
      })
  }

  _appendImages() {
    for (let i = 0; i < this.image_data.length; i++) {
      let img = new Image(),
        box = document.createElement('div'),
        menu = document.createElement('ul');

      for (let img_key in this.image_data[i]) {
        let url_item = document.createElement('li');
        url_item.dataset.imgUrl = this.image_data[i][img_key];
        url_item.innerHTML = img_key;
        menu.appendChild(url_item);
      }

      menu.style.display = 'none';
      img.src = this.image_data[i]['medium'];
      box.appendChild(menu);
      box.dataset.imagePosition = i;
      box.style.backgroundImage = 'url(\'' + this.image_data[i]['medium'] + '\')';
      this.existing_container.appendChild(box);
    }
  }

  _setupUploadListener() {
    $(this.submit_button).on('click', (e) => {
      e.preventDefault();
      if (this.uploading) return;

      this.uploading = true;
      this.submit_button.disabled = true;
      this.submit_button.innerHTML = '<i class="fa fa-spinner fa-spin"></i> Saving...';
      $(this).attr('data-form-selector', '.image-selector');
      $(this).attr('data-error-selector', '.error-container');
      let file = $(this.image_input)[0].files[0];

      if (!file) return false;

      let fd = new FormData();

      fd.append($(this.image_input).attr('name'), file);

      $.ajax({
        url: $(this.form).attr('action'),
        data: fd,
        contentType: false,
        dataType: 'json',
        method: 'PATCH',
        context: this,
        processData: false
      })
        .done(function(data) {
          if (this.afterUpload != undefined) {
            this.afterUpload(data);
            $(this.existing_tab).click();
          }
          else {
            $(this.existing_container).empty();
            this._populateImages();
            $(this.existing_tab).click();
          }
          this.uploading = false;
          this.submit_button.disabled = false;
          this.submit_button.innerHTML = 'Save';
        });
    });
  }

  _setupImageSelectListener() {
    $(this.existing_container).on('click', 'div', (e) => {
      $(e.target).find('ul').show();
    });
  }

  _setupImageSizeSelectListener() {
    $(this.existing_container).on('click', 'li', (e) => {
      this.onSelect(e.target.dataset.imgUrl);
      $(e.target).closest('ul').hide();
      this.modal.hide();
    });
  }

  _setupToggleListeners() {
    $(this.upload_tab).on('click', function(e) {
      e.stopPropagation();

      this._clearFileInput(this.image_input);
      if (this.selected_resource !== undefined) {
        this.selected_resource.deselect();
        this.selected_resource = undefined;
      }
      $(this.submit_button).removeClass('hide_important');
      $(this.upload_tab).addClass('selected');
      $(this.existing_tab).removeClass('selected');

      this.upload_container.style.display = 'flex';
      this.existing_container.style.display = 'none';
    }.bind(this));

    $(this.existing_tab).on('click', function(e) {
      e.stopPropagation();

      this._clearFileInput(this.image_input);
      if (this.selected_resource !== undefined) {
        this.selected_resource.deselect();
        this.selected_resource = undefined;
      }
      $(this.submit_button).addClass('hide_important');
      $(this.existing_tab).addClass('selected');
      $(this.upload_tab).removeClass('selected');

      this.upload_container.style.display = 'none';
      this.existing_container.style.display = 'flex';
    }.bind(this));
  }

  _clearFileInput(input) {
    try {
      input.value = null;
    } catch(ex) { }
    if (input.value) {
      input.parentNode.replaceChild(input.cloneNode(true), input);
    }
  }

  show() {
    this.modal.show();
  }

  destroy() {
    delete this.modal;
    $(this.container).remove();
  }

}
