define([], function() {
  function TCIAlert(config) {
    if (config) {
      this.confirm = config.confirm || false;
      this.confirm_action = config.confirm_action;
    }
  }

  TCIAlert.prototype = {
    init: function() {
      const this_obj = this;

      this._build();

      /** Setup listeners */
      $(this.confirm_button).on('click', function() {
        this_obj.close();
        if (this_obj.confirm) {
          this_obj.confirm_action();
        }
      });

      $(this.cancel_button).on('click', function() {
        this_obj.close();
      });
    },

    _build: function() {
      this.wrapper = document.createElement('div');
      this.wrapper.className = 'tci_alert_wrapper';
      this.message_box = document.createElement('div');
      this.wrapper.appendChild(this.message_box);

      this.button_box = document.createElement('div');
      this.button_box.className = 'pos_fr';

      if (this.confirm) {
        this.cancel_button = document.createElement('div');
        this.cancel_button.className = 'btn btn--link-purple mr10';
        this.cancel_button.innerHTML = 'Cancel';
      }

      this.confirm_button = document.createElement('div');
      this.confirm_button.className = 'btn btn--purple';
      this.confirm_button.innerHTML = 'OK';
      if (this.confirm) {
        this.button_box.appendChild(this.cancel_button);
      }
      this.button_box.appendChild(this.confirm_button);
      this.wrapper.appendChild(this.button_box);

      document.body.appendChild(this.wrapper);
    },

    showWithMessage: function(message) {
      this.message_box.innerHTML = message;

      this._center();
      this.wrapper.style.display = 'block';
    },

    _center: function() {
      const width = $(this.wrapper).outerWidth();
      const height = $(this.wrapper).outerHeight();
      const screen_width = $(window).width();
      const screen_height = $(window).height();

      this.wrapper.style.top = ((screen_height - height) / 2) + $(window).scrollTop() + 'px';
      this.wrapper.style.left = ((screen_width - width) / 2) + 'px';
    },

    close: function() {
      this.wrapper.style.display = 'none';
    }
  };

  return TCIAlert;
});
