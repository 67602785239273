import GridCell from 'Grid/AdminGridCell';

export default class AdminGridRow {
  constructor(config) {
    this.cells = [];
    this.node = config.node;
    this.data = config.data || '';
    this.is_first_row = (config.index == 0);
    this.cols = config.cols;
    this.images = config.images;
    this.parent = config.parent;
  }

  /**
   * Initializes the grid row and builds all cells (and <td>'s)
   * @returns {AdminGridRow}
   */
  init() {
    let cell,
      cell_num = 0;

    for (let i = 0; i < this.cols; i++) {
      if (!this.data || (this.data && (this.cells.length < this.data.length))) {
        cell = new GridCell({
          node: this.node.insertCell(cell_num),
          parent: this,
          data: (this.data == undefined ? '' : this.data[cell_num]),
          images: this.images,
          position: i
        });

        cell.init();

        /** Use the first row to control column widths */
        if (this.is_first_row && !cell.width) {
          cell.setWidth(414, false);     // XXXX magic number :p
        }

        /** Set default height */
        if (!cell.height) {
          cell.setHeight(25, false);     // XXXX magic number :p
        }

        this.cells.push(cell);
        cell_num++;
      }
    }

    return this;
  }

  /**
   * Add (num_to_add) cells to grid row with first position equal to (start_position)
   * @param num_to_add
   * @param start_position
   * @return {Array} AdminGridRow.cells
   */

  addCells(num_to_add, start_position) {
    let cell;

    for (let i = 0; i < num_to_add; i++) {
      cell = new GridCell({
        node: this.node.insertCell(this.cells.length),
        parent: this,
        data: '',
        images: this.images,
        position: start_position + i
      });

      cell.init();

      this.cells.push(cell);
    }

    return this.cells;
  }

  /**
   * Remove (num) cells from grid row
   * @param num
   * @return {Array} AdminGridRow.cells
   */

  removeCells(num) {
    let many_cols = num;
    // delete all cells that are indexed beyond the number of chosen columns
    for (let i = this.cells.length; i > num; i--) {
      this.node.deleteCell(-1);
      this.cells.pop();
    }
    // go forward, counting how many columns each cell spans
    let colspan_counter = 0;
    for (let i = 0; i<this.cells.length; i++) {
      let cell = this.cells[i];
      colspan_counter += cell.span;

      // we have gone over the number of columns we want
      if (colspan_counter > many_cols) {
        // calculate how much colspan the cell should take up
        let excess_cols = many_cols - colspan_counter;

        // extra cell, delete
        if (cell.span + excess_cols <= 0) {
          this.node.deleteCell(-1);
          this.cells.pop();
          break;
        }
        // reset for correct amount of colspan
        else if (excess_cols < 0) {
          cell.setSpan(cell.span + excess_cols);
        }
      }
    }

    /** TODO: should this return the removed cells? */
    return this.cells;
  }

  /**
   * Merge target cell with neighbor to the right
   * @param target
   * @return {Object} AdminGridCell
   */

  mergeCellsRight(target) {
    let target_index = this.cells.indexOf(target),
      destination  = this.cells[target_index + 1];

    if (!destination || (destination.rowspan !== target.rowspan) || ((target.position + target.span) !==  destination.position)) {
      return false;
    }


    target.setWidth(parseInt(destination.width, 10) + parseInt(target.width, 10));
    target.insertContent(destination.val);
    target.setSpan(target.span + destination.span);

    /** Bye bye destination cell */
    this.node.removeChild(destination.node);
    this.cells.splice(target_index + 1, 1);

    this.parent.madeChange();

    return target;
  }

  mergeCellsBelow(target) {
    let destination_row = this.parent.findRowBelow(this, target.rowspan),
      destination_cell = null;

    if (destination_row) {
      for (let i = 0; i < destination_row.cells.length; i++) {
        if (destination_row.cells[i].position === target.position) {
          destination_cell = destination_row.cells[i];
          break;
        }
        else if (destination_row.cells[i].position > target.position) {
          break;
        }
      }
    }

    this.parent.madeChange();

    if (!destination_row || !destination_cell || target.span !== destination_cell.span) {
      return false;
    }

    target.height = parseInt(destination_cell.height, 10) + parseInt(target.height, 10);
    target.insertContent(destination_cell.val);
    target.setRowSpan(target.rowspan + destination_cell.rowspan);

    /** Bye bye destination cell */
    destination_row.node.removeChild(destination_cell.node);
    destination_row.cells.splice(destination_row.cells.indexOf(destination_cell), 1);

    // target.setHeight(null);

    return target;
  }

  destroy() {
    this.cells.forEach((cell) => {
      cell.destroy();
    });

    this.parent.madeChange();
  }

  /**
   * Converts row to stringified JSON
   * @returns {string}
   */

  toString() {
    let output = '[';

    for (let i = 0; i < this.cells.length; i++) {
      output += this.cells[i].toString();
      output += ', ';
    }

    /** Remove ' ' and ',' from string after loop finishes */
    output = output.slice(0, -2);

    output += ']';

    return output;
  }
}