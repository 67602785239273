export default class ErrorChecker {
  static getBlanks(questionText) {
    // Each blank is anything (including newlines) wrapped in brackets:
    return questionText.match(/\[[\s\S]*?\]/g);
  }

  // Returns true if there are no fill in the blank questions
  // Ex: The sky is blue.
  static checkNoBlanksError(string) {
    return string.indexOf('[') === -1;
  }

  // Returns true if there is not one closing bracket for every opening
  // bracket, or if there are nested brackets
  // Ex: The sky is [blue*,red
  // Ex: The sky is [green,[blue*,red],purple]
  static checkBracketsError(string) {
    // bracketCurrentlyOpen is true if an opening bracket [ has been detected, but
    // its corresponding closing bracket ] has not yet
    let bracketCurrentlyOpen = false;
    let errorDetected = false;
    const characters = string.split('');

    // Iterate over each character
    // If an opening bracket is detected while another one is still open, mark an error
    // Ex: The sky is [ [blue]
    // If a closing bracket is detected while no opening bracket is still open, mark an error
    // Ex: The sky is [blue] ]
    characters.forEach(function(character) {
      if (character === '[') {
        if (bracketCurrentlyOpen) errorDetected = true;
        bracketCurrentlyOpen = true;
      }
      else if (character === ']') {
        if (!bracketCurrentlyOpen) errorDetected = true;
        bracketCurrentlyOpen = false;
      }
    });

    errorDetected = errorDetected || bracketCurrentlyOpen;
    return errorDetected;
  }

  // Returns true if a blank has fewer than two options
  // Ex: The sky is [blue*]
  static checkInsufficientOptionsError(string) {
    const blanks = this.getBlanks(string);
    let errorDetected = false;
    if (!blanks) return false;

    blanks.forEach(function(blank) {
      const numberOfOptions = blank.split(',').length;
      if (numberOfOptions < 2 || numberOfOptions > 4) errorDetected = true;
    });

    return errorDetected;
  }

  // Returns true if a blank has either zero or more than two correct answers
  // Ex: The sky is [blue,red]
  // Ex: The sky is [blue*,red*]
  static checkInvalidAnswerError(string) {
    const blanks = this.getBlanks(string);
    let errorDetected = false;
    if (!blanks) return false;

    blanks.forEach(function(blank) {
      let numCorrectAnswers = 0;
      const options = blank.split(',');
      options.forEach(function(option) {
        if (option.includes('*')) numCorrectAnswers++;
      });

      if (numCorrectAnswers !== 1) errorDetected = true;
    });

    return errorDetected;
  }

  // Returns true if any of the answer options are blank
  // Ex: The sky is [blue*,red,]
  // Ex: The sky is [blue*,,purple]
  static checkEmptyOptionError(string) {
    const blanks = this.getBlanks(string);
    const startingBlankRegex = /\[\s*,/;
    const middleBlankRegex = /,\s*\]/;
    const endingBlankRegex = /,\s*,/;
    let errorDetected = false;
    if (!blanks) return false;

    blanks.forEach(function(blank) {
      if (blank.match(startingBlankRegex) ||
        blank.match(middleBlankRegex) ||
        blank.match(endingBlankRegex)) {
        errorDetected = true;
      }
    });

    return errorDetected;
  }

  // Returns true if any of the answer options are longer than 30 characters
  // Ex: The sky is [blue*, red, super long striiiiiiiiiiiiiiiiiiiiiiiiiiiiing].
  static checkOptionLengthError(string) {
    const blanks = this.getBlanks(string);
    let errorDetected = false;
    if (!blanks) return false;

    blanks.forEach(function(blank) {
      const options = blank.split(',');
      options.forEach(function(option) {
        const optionWithoutBrackets = option.replace('[', '').replace(']', '');
        if (optionWithoutBrackets.length > 100) errorDetected = true;
      });
    });

    return errorDetected;
  }

  // Returns true if the translations don't have the same number of blanks and choices:
  // Ex: English translation is "[x*, y]" but Spanish translation is "[a*, b][c*, d]"
  // Ex: English translation is "[x*, y]" but Spanish translation is "[a, b*, c]"
  // Ex: English translation is "[x*, y]" but Spanish translation is "[a, b*]"
  static checkTranslationOptionsMatch(originalString, translationString) {
    const BLANKS_REGEX = /\[.*?\]/g;
    const originalBlanks = originalString.match(BLANKS_REGEX);
    const translationsBlanks = translationString.match(BLANKS_REGEX);

    if (!originalBlanks || !translationsBlanks) return true;

    return this.checkBlanksMatch(originalBlanks, translationsBlanks);
  }

  // Returns true if the translations don't have the same number of blanks and choices:
  // Ex: English translation is "[x*, y]" but Spanish translation is "[a*, b][c*, d]"
  static checkBlanksMatch(originalBlanks, translationsBlanks) {
    if (originalBlanks.length !== translationsBlanks.length) return true;

    return originalBlanks.find((originalBlank, index) => {
      const originalOptions = originalBlank.split(',');
      const translationOptions = translationsBlanks[index].split(',');

      return this.checkOptionsMatch(originalOptions, translationOptions);
    });
  }

  // Returns true if the translations don't have the same number of choices or the correct choice position is different:
  // Ex: English translation is "[x*, y]" but Spanish translation is "[a, b*, c]"
  // Ex: English translation is "[x*, y]" but Spanish translation is "[a, b*]"
  static checkOptionsMatch(originalOptions, translationOptions) {
    if (originalOptions.length !== translationOptions.length) return true;

    return originalOptions.find((originalOption, optionIndex) => {
      const translationOption = translationOptions[optionIndex];

      return (originalOption.includes('*') !== translationOption.includes('*'));
    });
  }

  // Check if the text for a fill in the blank question contains any of the six markup errors
  // Returns the error message string if an error is found, otherwise returns false
  static checkString(string) {
    let errorText = '';

    if (this.checkNoBlanksError(string)) {
      errorText += 'Must create at least one fill-in-the-blank with brackets.';
    }
    if (this.checkBracketsError(string)) {
      errorText += 'All opening brackets must have a closing bracket and vice versa. ';
    }
    if (this.checkInsufficientOptionsError(string)) {
      errorText += 'Each blank requires 2-4 choices. ';
    }
    if (this.checkInvalidAnswerError(string)) {
      errorText += 'Identify one correct choice with an asterisk. ';
    }
    if (this.checkEmptyOptionError(string)) {
      errorText += 'No answer options can be blank. ';
    }
    if (this.checkOptionLengthError(string)) {
      errorText += 'Limit each choice to 100 characters.';
    }

    return errorText.length > 0 ? errorText : false;
  }

  static checkTranslations(englishString, spanishString) {
    // Skip if the Spanish translation matches the placeholder text:
    if (spanishString.match(/^Esta pregunta no tiene traducción al español. Cambie a inglés \[.*?\]/)) return false;

    if (this.checkTranslationOptionsMatch(englishString, spanishString)) {
      return 'Spanish translation must contain the same number of blanks, answer choices, ' +
        'and correct answer choices identified with an asterisk as the English translation.';
    }

    return false;
  }
}
