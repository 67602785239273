import GridToolbar from './GridToolbar';
import AdminGridRow from './AdminGridRow';

function hasNaN(arr) {
  let hasEmpty = false;
  arr.forEach(function checkNaN(val) {
    if (isNaN(val)) {
      hasEmpty = true;
    }
  });

  return hasEmpty;
}

export default class GridBuilder {
  constructor(config) {
    this.id = config.id;

    this.images_url = config.images_url;

    this.$form = $(`#${config.id}`).closest('form');

    this.$col_input = this.$form.find('.columns');
    this.$row_input = this.$form.find('.rows');
    this.$chunk = this.$form.find('.chunk');

    this.$row_ratios_input = this.$form.find('.row_ratios');
    this.$col_ratios_input = this.$form.find('.col_ratios');

    this.$height = this.$form.find('.height');
    this.height = parseInt(this.$height.val(), 10);

    this.num_rows = parseInt(this.$row_input.val(), 10);
    this.num_cols = parseInt(this.$col_input.val(), 10);

    this.question_id = this.$form.data('question-id');

    this.images_container = config.images_container;

    this.$column_inputs_container = this.$form.find('.column-inputs-container');
    this.$row_inputs_container = this.$form.find('.row-inputs-container');

    this.$update_percentages_button = this.$form.find('.update-percentages');

    this.chunk = (this.$chunk.val() === '' || this.$chunk.val() === undefined) ? '' : JSON.parse(this.$chunk.val());
    /** Parse JSON data if it exists */
    this.rows = [];
    this.gutters = [];
    this.images = [];
  }

  /**
   * Initializes the grid (includes constructing DOM elements)
   * @return {GridBuilder}
   */
  init() {
    this._getImages();

    this._buildWrapper();

    this._buildTable();

    this._buildToolbar();

    this._setupRatios();

    this._buildRatioControls();

    this._setupUpdateListener();

    this._setupRatioInputListeners();

    this._setupAddRemoveListeners();

    return this;
  }

  _getImages() {
    if (this.images_container !== undefined) {
      $(this.images_container).find('.display-image').each(function addImage(i, el) {
        this.images.push({
          name: el.getAttribute('data-name'),
          url: el.getAttribute('data-display-src'),
          id: el.getAttribute('data-image-id')
        });
      }.bind(this));
    }
  }

  _setupRatios() {
    const total_cols = this.$col_ratios_input.val().split(',').reduce((acc, current) => parseInt(acc, 10) + parseInt(current, 10));
    const total_rows = this.$row_ratios_input.val().split(',').reduce((acc, current) => parseInt(acc, 10) + parseInt(current, 10));

    this.ratios = {
      columns: this.$col_ratios_input.val().split(',').map(x => parseInt(x / total_cols * 100, 10)),
      rows: this.$row_ratios_input.val().split(',').map(x => parseInt(x / total_rows * 100, 10))
    };
    // non-legacy
    if (!(hasNaN(this.ratios.columns) && hasNaN(this.ratios.rows))) {
      this.updateColumnWidths(this.ratios.columns);
      this.updateRowHeights(this.ratios.rows);
    }
    // legacy
    else {
      // disable form save
      this.$form.find('[type="submit"]').attr('disabled', 'disabled');
      this.$form.find('a.delete.action').attr('disabled', 'disabled');
    }
  }

  _setupAddRemoveListeners() {
    this.$form.find('.add-column').on('click', function handleAddCol() {
      this.addColumn();
    }.bind(this));
    this.$form.find('.remove-column').on('click', function handleRemoveCol() {
      this.removeColumn();
    }.bind(this));
    this.$form.find('.add-row').on('click', function handleAddRow() {
      this.addRow();
    }.bind(this));
    this.$form.find('.remove-row').on('click', function handleRemoveRow() {
      this.removeRow();
    }.bind(this));
  }

  addColumn() {
    const column_widths = this.getColumnWidthsFromInputs();
    if (!hasNaN(column_widths)) {
      this.updateColsNum(this.num_cols + 1);
      // will add an column ratio input with default value of 1
      this.addColumnInput();
      let buffer = 0;

      // We will probably want to modify this for a number > 1
      const buffered_widths = column_widths.map((col) => {
        let adjusted_col = col;

        if (buffer < 1) {
          if (col > 1) {
            adjusted_col--;
            buffer++;
          }
        }
        return adjusted_col;
      });

      this.updateColumnWidths(buffered_widths.concat(1));
    }
  }

  removeColumn() {
    const column_widths = this.getColumnWidthsFromInputs();
    if (!hasNaN(column_widths) && confirm('Are you sure you want to remove a column?')) {
      this.updateColsNum(this.num_cols - 1);
      this.removeColumnInput();
      const removed_width = this.ratios.columns.pop();
      this.ratios.columns[0] += removed_width;
      this.updateColumnWidths(this.ratios.columns);
    }
  }

  addRow() {
    const row_heights = this.getRowHeightsFromInputs();
    if (!hasNaN(row_heights)) {
      this.updateRowNum(this.num_rows + 1);
      // will add an column ratio input with default value of 1
      this.addRowInput();

      let buffer = 0;

      // We will probably want to modify this for a number > 1
      const buffered_heights = row_heights.map((row) => {
        let adjusted_row = row;

        if (buffer < 1) {
          if (row > 1) {
            adjusted_row--;
            buffer++;
          }
        }
        return adjusted_row;
      });
      this.updateRowHeights(buffered_heights.concat(1));
    }
  }

  removeRow() {
    const row_heights = this.getRowHeightsFromInputs();
    if (!hasNaN(row_heights) && confirm('Are you sure you want to remove a row?')) {
      this.updateRowNum(this.num_rows - 1);
      this.removeRowInput();
      const removed_height = this.ratios.rows.pop();
      this.ratios.rows[0] += removed_height;
      this.updateRowHeights(this.ratios.rows);
    }
  }

  addColumnInput() {
    const $input = $('<div class="col-input-wrapper"><input type="number" min="1" step="1" class="column-ratio" value="1"></input><span class="percent">%</span></div>');
    this.$form.find('.column-inputs-container').append($input);
  }

  addRowInput() {
    const $input = $('<div class="row-input-wrapper"><input type="number" min="1" step="1" class="row-ratio" value="1"></input><span class="percent">%</span></div>');
    this.$form.find('.row-inputs-container').append($input);
  }

  removeColumnInput() {
    this.$column_inputs_container.find('div.col-input-wrapper:last-of-type').remove();
  }

  removeRowInput() {
    this.$row_inputs_container.find('div.row-input-wrapper:last-of-type').remove();
  }

  getColumnWidthsFromInputs() {
    const column_widths = [];
    this.$column_inputs_container.find('input').each(function addColWidth(index, input) {
      const value = parseInt(input.value, 10);
      column_widths.push(value);
    });
    return column_widths;
  }

  getRowHeightsFromInputs() {
    const row_heights = [];
    this.$row_inputs_container.find('input').each(function addRowHeight(index, input) {
      const value = parseInt(input.value, 10);
      row_heights.push(value);
    });
    return row_heights;
  }

  _setupUpdateListener() {
    this.$update_percentages_button.on('click', () => {
      const errors = this.checkValidPercentages();

      if (errors.length > 0) {
        const message = `${errors.join(' and ')} must equal 100%`;

        alert(message);
      }
      else {
        const column_widths = this.getColumnWidthsFromInputs();
        // there are no empty column inputs
        if (!hasNaN(column_widths)) {
          this.updateColumnWidths(column_widths);
        }
        const row_heights = this.getRowHeightsFromInputs();
        // there are no empty inputs
        if (!hasNaN(row_heights)) {
          this.updateRowHeights(row_heights);
        }
      }
    });
  }

  _setupRatioInputListeners() {
    this.$form.find('.grid-inputs input').on('change', function handleInputChange(e) {
      if (e.target.id === 'height') {
        const height = parseInt(this.$height.val(), 10);
        // this acts as a min-height
        if (!isNaN(height)) {
          this.updateTableHeight(height);
        }
      }
    }.bind(this));

    this.$form.find('.column-inputs-container input, .row-inputs-container input').on('change', () => {
      this.$form.find('.validate_submit').attr('disabled', 'disabled');
    });
  }

  checkValidPercentages() {
    let col_total = 0;
    let row_total = 0;

    const errors = [];

    this.$form.find('.column-inputs-container input').each(function addToCols() {
      col_total += parseInt(this.value, 0);
    });

    this.$form.find('.row-inputs-container input').each(function addToRows() {
      row_total += parseInt(this.value, 0);
    });

    if (col_total !== 100) {
      errors.push('Columns');
    }

    if (row_total !== 100) {
      errors.push('Rows');
    }

    if (errors.length === 0) {
      this.$form.find('.validate_submit').removeAttr('disabled');
    }
    return errors;
  }

  updateTableHeight(height) {
    this.height = height;
    $(this.table_element).parent().css({ height: height });
    this.$row_inputs_container.css({ height: height });
    if (this.row_control_wrapper) {
      this.row_control_wrapper.style.height = `${this.height}px`;
    }
  }

  getRatios() {
    const get_row_heights = arr => arr.reduce(
      (acc, row) => acc.concat(parseInt(row.node.offsetHeight, 10)),
      []
    );

    const get_column_widths = arr => arr.reduce(
      (acc, cell) => acc.concat(parseInt(cell.node.offsetWidth, 10)),
      []
    );

    const height = this.table_element.offsetHeight;
    const row_heights = get_row_heights(this.rows);

    let index = 0;
    while (this.rows[index].cells.length !== this.num_cols) {
      index++;
    }

    const column_widths = get_column_widths(this.rows[index].cells);

    return {
      columns: column_widths,
      rows: row_heights,
      height: height
    };
  }

  populateRatioInputs() {
    this.ratios = this.getRatios();

    this.$height.val(this.ratios.height);
    this.$column_inputs_container.find('input').each(function setColInputValue(index, input) {
      const input_el = input;

      input_el.value = this.ratios.columns[index];
    }.bind(this));

    this.$row_inputs_container.find('input').each(function setRowInputValue(index, input) {
      const input_el = input;

      input_el.value = this.ratios.rows[index];
    }.bind(this));

    this.updateTableHeight(this.ratios.height);
    this.updateColumnWidths(this.ratios.columns);
    this.updateRowHeights(this.ratios.rows);
  }


  /**
   * Builds wrapping DOM elements
   * @private
   * @return {object} wrapper
   */

  _buildWrapper() {
    return $(`#${this.id}`).html('<div id="admin_grid_table_wrapper"></div>');
  }

  /**
   * Builds table (Objects and DOM)
   * @private
   * @return {object} AdminGrid.table_element
   */

  _buildTable() {
    const wrapper = this.$form.find('#admin_grid_table_wrapper')[0];
    let row;

    wrapper.innerHTML = `<table id="${this.id}_table"></table>`;

    this.table_element = document.getElementById(`${this.id}_table`);
    this.updateTableHeight(this.height);
    for (let i = 0; i < this.num_rows; i++) {
      row = new AdminGridRow({
        node: this.table_element.insertRow(i),
        data: this.chunk[i],
        cols: this.num_cols,
        index: i,
        images: this.images,
        parent: this
      });
      row.init();

      this.rows.push(row);
    }

    return this.table_element;
  }

  /**
   * Builds a global toolbar for cell formatting
   * @private
   * @return {GridToolbar}
   */
  _buildToolbar() {
    this.toolbar = new GridToolbar({
      grid: this
    });

    this.toolbar.init();

    return this.toolbar;
  }

  _buildRatioControls() {
    const wrapper = this.$form.find('#admin_grid_table_wrapper')[0];

    this.row_control_wrapper = document.createElement('div');
    this.row_control_wrapper.style.height = `${this.height}px`;
    this.row_control_wrapper.className = 'row_control_wrapper';
    let row_controls = '<div class="remove-row"><i class="fa fa-minus"></i></div>';
    row_controls += '<div class="add-row"><i class="fa fa-plus"></i></div>';
    this.row_control_wrapper.innerHTML = row_controls;

    this.column_control_wrapper = document.createElement('div');
    this.column_control_wrapper.className = 'column_control_wrapper';
    let column_controls = '<div class="remove-column"><i class="fa fa-minus"></i></div>';
    column_controls += '<div class="add-column"><div><i class="fa fa-plus"></i></div>';
    this.column_control_wrapper.innerHTML = column_controls;

    wrapper.appendChild(this.row_control_wrapper);
    wrapper.appendChild(this.column_control_wrapper);
  }

  findRowBelow(row, rowspan) {
    let row_return = false;

    if ((this.rows.indexOf(row) + rowspan) < this.rows.length) {
      row_return = this.rows[this.rows.indexOf(row) + rowspan];
    }

    return row_return;
  }


  /**
   * Adds or removes rows from the end of the table to
   * get to (num) rows
   * @param num
   * @return {AdminGrid}
   */

  updateRowNum(num) {
    let row;
    let i;
    // update hidden input row value
    this.$row_input.val(num);

    /** Adding rows */
    if (num > this.num_rows) {
      for (i = 0; i < num - this.num_rows; i++) {
        row = new AdminGridRow({
          node: this.table_element.insertRow(this.num_rows + i),
          data: '',
          cols: this.num_cols,
          index: this.rows.length,
          images: this.images,
          parent: this,
          position: i
        });
        row.init();

        this.rows.push(row);
      }
      this.num_rows = num;
    }
    /** Removing rows */
    else if (num < this.num_rows) {
      for (i = this.num_rows; i > num; i--) {
        this.table_element.deleteRow(-1);
        this.rows.pop();
      }
      this.num_rows = num;
    }

    return this;
  }

  /**
   * Adds or removes columns from the end of the table to
   * get to (num) columns
   * @param num
   * @return {number} AdminGrid.num_cols
   */

  updateColsNum(num) {
    let i;

    this.$col_input.val(num);

    /** Adding columns */
    if (num > this.num_cols) {
      for (i = 0; i < this.num_rows; i++) {
        this.rows[i].addCells(num - this.num_cols, this.num_cols);
      }
      this.num_cols = num;
    }
    /** Removing columns */
    else if (num < this.num_cols) {
      for (i = 0; i < this.num_rows; i++) {
        this.rows[i].removeCells(num);
      }
      this.num_cols = num;
    }

    return this.num_cols;
  }

  updateRowHeights(ratios) {
    this.ratios.rows = ratios;

    if (ratios.length !== this.num_rows) {
      this.updateRowNum(ratios.length);
    }

    this.rows.forEach(function setRowHeight(elem, i) {
      elem.cells.forEach(function setCellHeight(cell) {
        cell.setHeight(`${parseInt(ratios[i], 10)}%`);
      });
    });

    // Set row input wrapper styling to match row heights
    for (let k = 0; k < this.ratios.rows.length; k++) {
      const $row_input_wrap = this.$row_inputs_container.find(`div:eq(${k})`);
      $row_input_wrap[0].style.height = `${parseInt(this.ratios.rows[k], 10)}%`;
      $row_input_wrap.find('input').val(parseInt(this.ratios.rows[k], 10));
    }
  }

  updateColumnWidths(ratios) {
    this.ratios.columns = ratios;

    if (ratios.length !== this.num_cols) {
      this.updateColsNum(ratios.length);
    }

    this.rows.forEach(function updateRow(elem) {
      let ratio_counter = 0;

      for (let j = 0; j < elem.cells.length; j++) {
        const cell = elem.cells[j];
        // multiple column cell: add up ratios for all columns cell spans
        // if a cell spans multiple columns, we keep track of where we are
        // in the ratios array with the ratio counter
        if (cell.span > 1) {
          let total_percent = 0;
          for (let k = j; k < (j + cell.span); k++) {
            total_percent += ratios[k];
          }
          cell.setWidth(`${total_percent}%`);
          ratio_counter += cell.span;
        }
        else {
          cell.setWidth(`${parseInt(ratios[ratio_counter], 10)}%`);
          ratio_counter += 1;
        }
      }
    });

    // Set column input wrapper styling to match column widths
    for (let k = 0; k < this.ratios.columns.length; k++) {
      const $col_input_wrap = this.$column_inputs_container.find(`div:eq(${k})`);
      $col_input_wrap[0].style.width = `${parseInt(this.ratios.columns[k], 10)}%`;
      $col_input_wrap.find('input').val(parseInt(this.ratios.columns[k], 10));
    }
  }

  /**
   * Converts grid to stringified JSON
   * for save to db
   * @returns {string}
   */

  toString() {
    let output = '[';

    for (let i = 0; i < this.rows.length; i++) {
      output += this.rows[i].toString();
      output += ', ';
    }

    /** Remove the ',' and the ' ' from the end of the string */
    output = output.slice(0, -2);
    output += ']';

    return output;
  }
}
