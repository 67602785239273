define(['TCIUtils'], function(TCIUtils) {
  function Spinner(config) {
    this.text = config.text || 'Please wait...';
    this.background_color = config.background_color;
    this.container = config.container || document.body;
  }

  Spinner.prototype = {
    build: function() {
      this.background = document.createElement('div');
      this.background.className = 'spinner__overlay';
      if (this.background_color)
        this.background.style.backgroundColor = this.background_color;

      this.container.appendChild(this.background);

      this.spinner_box = document.createElement('div');
      this.spinner_box.className = 'spinner__box';
      this.spinner_box.innerHTML = '<i class="fa fa-spinner fa-spin"></i>';

      this.message_container = document.createElement('span');
      this.message_container.className = 'spinner__message';
      this.message_container.innerText = this.text;

      this.spinner_box.appendChild(this.message_container);
      this.background.appendChild(this.spinner_box);

    },

    show: function(options) {
      TCIUtils.centerOnScreen(this.spinner_box);

      this.text = (options && options.text) || this.text;
      this.background.style.display = 'block';
      this.spinner_box.style.display = 'block';
    },

    hide: function() {
      this.background.style.display = 'none';
    }
  };

  return Spinner
});
