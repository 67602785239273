define([], function() {
  /** Lay out your HTML as follows:
    <div class="tci_tabs_container">
      <span class="tab active">First Tab</span>
      ...
      <span class="tab">Last Tab</span>

      <div class="tabbed_content active">
        First tab's content
      </div>
      ...
      <div class="tabbed_content">
        Last tab's content
      </div>
    </div>
  **/

  function Tabs(config) {
    this.container = config.container;
    this.tabs = this.container.querySelectorAll('.tab');
    this.contentAreas = this.container.querySelectorAll('.tabbed_content');
    this.onTabCallback = config.onTabCallback || (() => {});
  }

  Tabs.prototype = {
    init: function() {
      this._setActiveTab();
      this._setupListeners(this.tabs);
    },

    _setupListeners: function() {
      for (let i = 0; i < this.tabs.length; i++) {
        $(this.tabs[i]).on('click', function(e) {
          this._makeActiveTab($(e.target));
          this._toggleVisibility();
          if ($(this.container).hasClass('on-tab')) {
            this.onTabCallback($(e.target));
          }
        }.bind(this));
      }
    },

    _makeActiveTab: function($tab) {
      if (!$tab.hasClass('active')) {
        $(this.tabs).each(function() {
          $(this).removeClass('active');
        });

        $tab.addClass('active');
        this._setActiveTab();
      }
    },

    _setActiveTab: function() {
      for (let i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i].className.indexOf('active') > -1) {
          this.activeTab = i;
          return;
        }
      }
    },

    _toggleVisibility: function() {
      for (let i = 0; i < this.contentAreas.length; i++) {
        if (i % this.tabs.length !== this.activeTab) {
          $(this.contentAreas[i]).removeClass('active');
        }
        else {
          $(this.contentAreas[i]).addClass('active');
        }
      }
    }
  };

  return Tabs;
});
