define([], function() {
  function buildDropdown($chosenSelects) {
    $chosenSelects.each(function() {
      const noResultsText = this.getAttribute('data-no-results-text');

      $(this).chosen({
        no_results_text: noResultsText,
        search_contains: true,
        width: this.getAttribute('data-width')
      });

      if (noResultsText) {
        $(this).next().addClass('no-results-text');
      }
    });

    $('.search-field').css('width', '100%');
    $('.chosen-search-input').css('width', '100%');
  }

  function buildDropdownListeners($chosenSelects) {
    $chosenSelects.on('chosen:hiding_dropdown',function(){
      let $element = $(this).parent();
      $element.css('max-height', '');
      $element.attr('curr-height', $element.height());
    });

    $chosenSelects.on('chosen:showing_dropdown',function(){
      let $element = $(this).parent();
      let curr_height = $element.attr('curr-height');
      $element.css('max-height', parseInt(curr_height));
    });
  }

  return {
    init: function(container) {
      const $chosenSelects = container === undefined ? $('.chosen-select') : $(container).find('.chosen-select');
      buildDropdownListeners($chosenSelects);
      buildDropdown($chosenSelects);
    }
  };
});
