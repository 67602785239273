import React from 'react';
import ReactDOM from 'react-dom';
import ImageSelector from '../media_manager/ImageSelector';
import TCIAlert from '../TCIAlert';
import GridImageModal from '../../components/admin/Grid/GridImageModal';

const BACKGROUND_COLORS = {
  purple: '#8b90bd',
  orange: '#ecb689',
  green: '#cdd88b'
};

export default class GridToolbar {
  constructor(config) {
    this.grid = config.grid;
  }

  init() {
    this._build();
    this.hide_font_menu = true;
    this.hide_background_menu = true;
    $(this.background_color_selector_menu).hide();
    $(this.wrapper).hide();

    this._buildListeners();

    this._initImageModal();
  }

  _initImageModal() {
    const controls = document.querySelector('.admin-grid-controls');
    const userType = controls.classList[1];

    ReactDOM.render(
      <GridImageModal
        userType={userType}
        questionId={this.grid.form.id.split('-')[1]}
        form={this.grid.form}
        root={this.modalRoot}
      />,
      this.modalRoot
    );
  }

  _build() {
    // break this up
    this.wrapper = document.createElement('div');
    this.wrapper.className = 'grid-toolbar-wrapper';

    this.modalRoot = document.createElement('div');
    this.modalRoot.id = 'grid-modal-root';
    this.modalRoot.classList.add('grid-modal-root');
    this.modalRoot.classList.add('grid-image-button');
    this.wrapper.appendChild(this.modalRoot);

    this.font_selector = document.createElement('div');
    this.font_selector.className = 'grid-font-selector';
    this.font_selector_icon = document.createElement('div');
    this.font_selector_icon.className = 'grid-font-selector-icon';
    this.font_selector.appendChild(this.font_selector_icon);
    this.font_selector_display_text = document.createElement('div');
    this.font_selector_display_text.className = 'grid-font-selector-display-text header-font';
    this.font_selector_display_text.innerHTML = 'Header font';
    this.font_selector.appendChild(this.font_selector_display_text);
    this.font_down_arrow = document.createElement('i');
    this.font_down_arrow.className = 'fa fa-angle-down';
    this.font_selector.appendChild(this.font_down_arrow);
    this.font_selector_menu = document.createElement('div');
    this.font_selector_menu.className = 'grid-font-selector-menu';
    this.font_selector_menu.innerHTML = `
        <ul>
          <li class='header-font'>Header font</li>
          <li class='info-font'>Info font</li>
          <li class='student-font'>Student font</li>
        </ul>`;
    this.font_selector.appendChild(this.font_selector_menu);
    this.font_selector.title = 'Select font style';
    this.wrapper.appendChild(this.font_selector);

    this.background_color_selector = document.createElement('div');
    this.background_color_selector.className = 'grid-background-color-selector';
    this.background_color_selector_menu = document.createElement('div');
    this.background_color_selector_menu.className = 'grid-background-color-selector-menu';
    this.background_color_selector_menu.innerHTML = `
      <div class='grid-background-color purple'></div>
      <div class='grid-background-color green'></div>
      <div class='grid-background-color orange'></div>
      <div class='grid-background-color none'>None</div>
    `;
    this.background_color_selector.appendChild(this.background_color_selector_menu);
    this.background_color_selector.title = 'Select cell background color';
    this.wrapper.appendChild(this.background_color_selector);


    this.align_left_button = document.createElement('div');
    this.align_left_button.className = 'grid-align-left-button';
    this.align_left_button.title = 'Align left';
    this.wrapper.appendChild(this.align_left_button);

    this.align_center_button = document.createElement('div');
    this.align_center_button.className = 'grid-align-center-button';
    this.align_center_button.title = 'Align center';
    this.wrapper.appendChild(this.align_center_button);

    this.align_right_button = document.createElement('div');
    this.align_right_button.className = 'grid-align-right-button';
    this.align_right_button.title = 'Align right';
    this.wrapper.appendChild(this.align_right_button);

    this.merge_right_button = document.createElement('div');
    this.merge_right_button.className = 'grid-merge-right-button';
    this.merge_right_button.title = 'Merge with cell to the right';
    this.wrapper.appendChild(this.merge_right_button);

    this.merge_below_button = document.createElement('div');
    this.merge_below_button.className = 'grid-merge-below-button';
    this.merge_below_button.title = 'Merge with cell below';
    this.wrapper.appendChild(this.merge_below_button);

    this.clear_cell_button = document.createElement('div');
    this.clear_cell_button.className = 'grid-clear-cell-button';
    this.clear_cell_button.title = 'Clear all contents from cell';
    this.wrapper.appendChild(this.clear_cell_button);

    this.close_button = document.createElement('div');
    this.close_button.className = 'grid-close-button';
    this.close_button.title = 'Close toolbar';
    this.wrapper.appendChild(this.close_button);

    $('body')[0].appendChild(this.wrapper);
  }

  _buildListeners() {
    $(this.wrapper).on('click', (e) => {
      this.grid.madeChange();
      // Clicking to show selector menu
      if (this.isFontSelectorTrigger(e.target) && this.hide_font_menu) {
        $(this.font_selector_menu).stop(true, true).fadeIn(100);
        this.hide_font_menu = false;
      }
      // Clicking to hide selector menu
      else if (this.isFontSelectorTrigger(e.target)) {
        $(this.font_selector_menu).stop(true, true).fadeOut(100);
        this.hide_font_menu = true;
      }
      // Clicking to show background selector menu
      else if (this.isBackgroundSelectorTrigger(e.target) && this.hide_background_menu) {
        $(this.background_color_selector_menu).stop(true, true).fadeIn(100);
        $(this.background_color_selector).addClass('selected');
        this.hide_background_menu = false;
      }
      // Clicking to hide background selector menu
      else if (this.isBackgroundSelectorTrigger(e.target)) {
        $(this.background_color_selector_menu).stop(true, true).fadeOut(100);
        $(this.background_color_selector).removeClass('selected');
        this.hide_background_menu = true;
      }
      else if ($(e.target).hasClass('grid-background-color')) {
        this.selectBackgroundColor(e.target);
        $(this.background_color_selector_menu).stop(true, true).fadeOut(100);
        this.hide_background_menu = true;
      }
      // Clicking to select a font preset
      else if (e.target.nodeName === 'LI') {
        $(this.wrapper).find('.selected').removeClass('selected');
        this.selectFont(e.target.className);
        $(this.font_selector_menu).stop(true, true).fadeOut(100);
        this.hide_font_menu = true;
      }
      // Clicking an alignment button
      else {
        let tci_alert;

        $(this.wrapper).find('.selected').removeClass('selected');
        switch (e.target) {
          case this.close_button:
            $(this.wrapper).stop(true, true).fadeOut(100);
            break;
          case this.align_left_button:
            this.cell.setAlign('left');
            $(e.target).addClass('selected');
            break;
          case this.align_center_button:
            this.cell.setAlign('center');
            $(e.target).addClass('selected');
            break;
          case this.align_right_button:
            this.cell.setAlign('right');
            $(e.target).addClass('selected');
            break;
          case this.merge_right_button:
            tci_alert = new TCIAlert({
              confirm: true,
              confirm_action: () => {
                this.cell.parent.mergeCellsRight(this.cell);
              }
            });

            tci_alert.init();
            tci_alert.showWithMessage('Are you sure you want to merge this cell with the one to the right?');
            break;
          case this.merge_below_button:
            tci_alert = new TCIAlert({
              confirm: true,
              confirm_action: () => {
                this.cell.parent.mergeCellsBelow(this.cell);
              }
            });

            tci_alert.init();
            tci_alert.showWithMessage('Are you sure you want to merge this cell with the one below it?');
            break;
          case this.clear_cell_button:
            tci_alert = new TCIAlert({
              confirm: true,
              confirm_action: () => {
                $(this.cell.node).find('img').remove();
                this.cell.syncVal();
                this.cell.updateContent('');
                this.cell.has_text = false;
                this.cell.setPlaceholderText();
              }
            });

            tci_alert.init();
            tci_alert.showWithMessage('Are you sure you want to clear this cell\'s contents?');
            break;
          default:
            break;
        }
      }
    });
  }

  selectFont(font, prevent_focus) {
    $(this.wrapper).find('.selected').removeClass('selected');
    switch (font) {
      case 'info-font':
        this.cell.setFont({
          name: 'Info',
          family: '"Crimson Text"',
          weight: 'normal',
          color: '#000000'
        });
        this.cell.setAlign('left', prevent_focus);
        this.align_left_button.classList.add('selected');
        this.font_selector_display_text.innerHTML = 'Info font';
        this.font_selector_display_text.className = 'grid-font-selector-display-text header-font info-font';
        break;
      case 'student-font':
        this.cell.setFont({
          name: 'Student',
          family: '"Bubbler One"',
          weight: 'normal',
          color: '#000000'
        });
        this.cell.setAlign('left', prevent_focus);
        this.align_left_button.classList.add('selected');
        this.font_selector_display_text.innerHTML = 'Student font';
        this.font_selector_display_text.className = 'grid-font-selector-display-text header-font student-font';
        break;
      default:
        this.cell.setFont({
          name: 'Header',
          weight: 'bold',
          color: '#53318e'
        });
        this.cell.setAlign('center', prevent_focus);
        this.align_center_button.classList.add('selected');
        this.font_selector_display_text.innerHTML = 'Header font';
        this.font_selector_display_text.className = 'grid-font-selector-display-text header-font header-font';
        break;
    }

    this.grid.madeChange();
  }

  selectBackgroundColor(el) {
    if ($(el).hasClass('purple')) {
      this.cell.setBackgroundColor(BACKGROUND_COLORS.purple);
    }
    else if ($(el).hasClass('green')) {
      this.cell.setBackgroundColor(BACKGROUND_COLORS.green);
    }
    else if ($(el).hasClass('orange')) {
      this.cell.setBackgroundColor(BACKGROUND_COLORS.orange);
    }
    else {
      this.cell.setBackgroundColor(null);
    }
  }

  isFontSelectorTrigger(el) {
    return (el === this.font_selector ||
    el === this.font_selector_display_text ||
    el === this.font_selector_icon ||
    el === this.font_down_arrow);
  }

  isBackgroundSelectorTrigger(el) {
    return (el === this.background_color_selector ||
      el === this.background_color_selector_icon);
  }

  /**
   * Since we share a toolbar, when we click into a cell, we have
   * to update the toolbar to match
   *
   * @param cell
   * @private
   */
  _syncCellFontStyle(cell) {
    this._syncFontSelector(cell.font && cell.font.family);
    this._syncAlignment(cell.justification);
  }

  _syncFontSelector(family) {
    switch (family) {
      case '"Crimson Text"':
      case "'Crimson Text'":
      case 'Crimson Text':
        this.font_selector_display_text.innerHTML = 'Info font';
        this.font_selector_display_text.className = 'grid-font-selector-display-text header-font info-font';
        this.cell.content_div.style.fontFamily = 'Crimson Text';
        this.cell.content_div.style.fontWeight = 'normal';
        this.cell.content_div.style.color = '#000000';
        this.cell.content_div.style.textAlign = 'left';
        break;
      case '"Bubbler One"':
      case "'Bubbler One'":
      case 'Bubbler One':
        this.font_selector_display_text.innerHTML = 'Student font';
        this.font_selector_display_text.className = 'grid-font-selector-display-text header-font student-font';
        this.cell.content_div.style.fontFamily = 'Bubbler One';
        this.cell.content_div.style.fontWeight = 'normal';
        this.cell.content_div.style.color = '#000000';
        this.cell.content_div.style.textAlign = 'left';
        break;
      default:
        this.font_selector_display_text.innerHTML = 'Header font';
        this.font_selector_display_text.className = 'grid-font-selector-display-text header-font header-font';
        this.cell.content_div.style.fontWeight = 'bold';
        this.cell.content_div.style.color = '#53318e';
        this.cell.content_div.style.textAlign = 'center';
        break;
    }
  }

  _syncAlignment(justification) {
    switch (justification) {
      case 'left':
        this.align_left_button.classList.add('selected');
        break;
      case 'right':
        this.align_right_button.classList.add('selected');
        break;
      default:
        this.align_center_button.classList.add('selected');
        break;
    }
  }

  attachAndShow(cell) {
    if (this.cell !== cell) {
      $(this.wrapper).stop(true, true).fadeOut(100);
      $(this.font_selector_menu).stop(true, true).fadeOut(100);
      this.hide_font_menu = true;
      $(this.wrapper).appendTo(cell.header_div);
      $(this.wrapper).find('.selected').removeClass('selected');

      this.cell = cell;

      this._syncCellFontStyle(cell);
    }

    $(this.wrapper).stop(true, true).fadeIn(100);
  }

  hide() {
    $(this.wrapper).stop(true, true).fadeOut(100);
  }

  destroy() {
    $(this.wrapper).remove();
  }
}
