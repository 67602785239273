export default class AdminGridCell {
  constructor(config) {
    this.node = (config.node === undefined ? null : config.node);
    this.parent = config.parent;
    this.images = config.images;
    this.position = config.position;
    this.data = config.data || {};
    this.val = null;
    this.has_text = false;
    this.style = {};
    this.span = 1;
    this.rowspan = 1;
    this.EMPTY_CELL_TEXT = 'Insert content or leave blank for students to edit.';
  }

  init() {
    this._buildCell();

    this.val = ($.isEmptyObject(this.data) ? '' : this.data.val);
    this.updateContent(this.val);

    /** If JSON has cell styling, apply it */
    if (this.data && this.data.style) {
      this._applyLoadedStyleData();
    }
    else {
      this.width = '';
      this.height = '';
      this.backgroundColor = 'inherit';
    }

    if (!this.has_text && !this.content_div.classList.contains('react-has-text')) {
      this.setPlaceholderText();
    }

    this._setupListeners();
  }

  /**
   * Implements cell styling loaded from JSON
   * @private
   */
  _applyLoadedStyleData() {
    /** Apply loaded width if it exists */
    this.width = (this.data.style.width !== undefined && this.data.style.width !== null) ? this.data.style.width : '';

    if (this.width) {
      this.setWidth(this.width);
    }

    /** Apply loaded height if it exists */
    this.height = (this.data.style.height !== undefined && this.data.style.height !== null) ? this.data.style.height : '';
    if (this.height) {
      this.setHeight(this.height);
    }

    /** Apply loaded colspan if it exists */
    this.span = (this.data.colspan !== undefined && this.data.colspan !== null) ? this.data.colspan : 1;


    if (this.span) {
      this.setSpan(this.span);
    }

    /** Apply loaded colspan if it exists */
    this.rowspan = (this.data.rowspan !== undefined && this.data.rowspan !== null) ? this.data.rowspan : 1;

    if (this.rowspan) {
      this.setRowSpan(this.rowspan);
    }

    /** Apply loaded font if it exists */
    this.font = (this.data.style.font !== undefined && this.data.style.font !== null) ?
      this.data.style.font : { weight: 'bold', color: '#53318e' };
    this.setFont(this.font);

    /** Apply loaded background color if it exists */
    this.backgroundColor = (this.data.style.backgroundColor !== undefined && this.data.style.backgroundColor !== null) ? this.data.style.backgroundColor : 'inherit';
    this.setBackgroundColor(this.backgroundColor);

    /** Apply loaded justification if it exist */
    this.justification = (this.data.style.textAlign !== undefined && this.data.style.textAlign !== null) ? this.data.style.textAlign : 'center';
    this.setAlign(this.justification);
  }

  _setupListeners() {
    this.content_div.setAttribute('contenteditable', 'true');

    /**
     *   Listeners
     */

    const $form = $(this.content_div).parents('form');
    this.content_div.addEventListener(`${$form.attr('id')}-SYNC`, () => {
      this.syncVal();
    });

    // sanitize any content pasted into cell, timeout of zero seconds delays execution
    // of statement enough for target element to be updated w pasted content
    $(this.node).on('paste', (e) => {
      const cell = e.target;
      setTimeout(function() {
        $(cell).text(cell.innerText);
      }, 0);
    });

    $(this.node).on('input', (e) => {
      const $cell = $(e.target);
      this.val = $cell.html();

      $cell.addClass('grid_admin_cell');
      /** If cell was not set to blank */
      if (this.isEmpty()) {
        this.has_text = false;
      }
      else {
        this.has_text = true;
        $cell.removeClass('empty_cell');
      }

      this.parent.parent.madeChange();
    });

    /**
     * Used to prevent clicks inside a cell from triggering the document listener
     * that hides the toolbar
     */
    $(this.wrapper_div).on('click', (e) => {
      if (e.target.classList.contains('propagate')) return;

      e.stopPropagation();
    });

    /** When editing a cell, change the content div's background color and show
     * toolbar */
    $(this.content_div).on('focus click', () => {
      $(this.content_div).addClass('editing');

      this.parent.parent.toolbar.attachAndShow(this);
      if (!this.has_text && !this.content_div.classList.contains('react-has-text')) {
        this.content_div.innerHTML = '';
        this.content_div.classList.remove('empty_cell');
      }
    });

    /** Revert the content div's background color to default when done editing  */
    $(this.content_div).on('blur', () => {
      $(this.content_div).removeClass('editing');
      if (this.has_text) {
        $(this.content_div).removeClass('empty_cell');
      }
      else if (this.content_div.classList.contains('react-has-text')) {
        $(this.content_div).removeClass('empty_cell');
      }
      else {
        this.setPlaceholderText();
        $(this.content_div).addClass('empty_cell');
      }
    });
  }

  /**
   * Build grid cell div elements
   * @returns {AdminGridCell}
   * @private
   */

  _buildCell() {
    this.wrapper_div = document.createElement('div');
    this.wrapper_div.className = 'grid_td_wrapper_div';

    this.header_div = document.createElement('div');
    this.header_div.className = 'grid_td_header_div';

    this.content_div = document.createElement('div');
    this.content_div.className = 'grid_td_content_div';

    this.node.appendChild(this.wrapper_div);
    this.wrapper_div.appendChild(this.header_div);
    this.wrapper_div.appendChild(this.content_div);

    return this;
  }

  /**
   * Set cell width (value, style)
   * @param w
   * @returns {AdminGridCell}
   */

  setWidth(w) {
    $(this.node).css({
      width: w
    });

    this.node.style.width = w;
    this.width = this.node.style.width;

    if (this.data !== '') {
      this.data.width = w;
    }

    if (this.styles) {
      this.styles.width = w;
    }

    return this;
  }

  setHeight(h) {
    $(this.node).css({
      height: h
    });

    this.height = h;

    if (this.data !== '') {
      this.data.height = h;
    }

    if (this.styles) {
      this.styles.height = h;
    }

    return this;
  }

  /**
   * Set colspan for td to value (span) or update colspan
   * to cell's current value
   * @param span
   * @returns {AdminGridCell}
   */
  setSpan(span) {
    this.node.colSpan = span;
    this.span = span;

    return this;
  }

  setRowSpan(rowspan) {
    this.node.rowSpan = rowspan;
    this.rowspan = rowspan;

    return this;
  }


  /**
   * Set font for td to value (font) or update font
   * to cell's current value
   * @param font
   * @returns {AdminGridCell}
   */
  setFont(font) {
    this.font = font;
    this.content_div.style.fontFamily = font.family || '';
    this.content_div.style.fontWeight = font.weight || 'bold';
    this.content_div.style.color = font.color || '#53318e';

    return this;
  }

  /**
   * Set background color
   * @param color
   * @returns {AdminGridCell}
   */
  setBackgroundColor(color) {
    this.backgroundColor = color;
    this.content_div.style.backgroundColor = color || 'inherit';

    return this;
  }

  /**
   * Set font alignment to value (justification) or update
   * justification to cell's current value
   * @param justification
   * @returns {AdminGridCell}
   */
  setAlign(justification, preventFocus) {
    this.justification = justification;
    this.content_div.style.textAlign = this.justification || 'center';
    if (!preventFocus) {
      $(this.content_div).focus();
    }

    return this;
  }

  /**
   * Overwrite current content with (content)
   * @param content
   * @param setPlaceHolders
   * @returns {AdminGridCell}
   */

  updateContent(content, setPlaceHolders = false) {
    const $cell = $(this.node);
    this.content_div.innerHTML = content;
    this.val = content;

    if (this.val !== '') {
      this.has_text = true;
      $cell.addClass('grid_admin_cell');
      $cell.removeClass('empty_cell');
      if (setPlaceHolders) this.removePlaceholderText();
    }
    else {
      this.has_text = false;
      $cell.removeClass('grid_admin_cell');
      $cell.addClass('empty_cell');
      if (setPlaceHolders) this.setPlaceholderText();
    }

    return this;
  }

  syncVal() {
    this.val = this.content_div.innerHTML;
  }

  /**
   *
   * Checks if the cell is empty according to our needs.
   *
   * NOTE: contenteditable elements have a quirky thing where they will leave a single <br> behind after
   * emptied of content. We don't want these phantom <br> tags. And with proper sizing controls, they are not
   * necessary for spacing.
   * @returns {boolean}
   */
  isEmpty() {
    if (this.val.trim() === '' || this.val.trim() === '<br>') {
      this.val = '';
      return true;
    }

    return false;
  }

  /**
   * Add (content) to end of current cell content
   * @param content
   * @returns {AdminGridCell}
   */

  insertContent(content) {
    if ($(this.placeholder_text).length) {
      this.removePlaceholderText();
    }
    /** Handle dom elements passed as param */
    if (typeof content === 'object') {
      if (content.tagName === 'IMG') {
        content.setAttribute('data-zoomurl', content.src);
      }
      this.content_div.appendChild(content);
      this.val = this.content_div.innerHTML;
    }
    /** If param is just a string, simply concatenate */
    else {
      this.content_div.innerHTML += content;
      this.val = this.content_div.innerHTML;
    }

    if (this.val !== '') {
      this.has_text = true;
      $(this.node).addClass('grid_admin_cell')
        .removeClass('empty_cell');
    }
    this.parent.parent.madeChange();

    return this;
  }

  setPlaceholderText() {
    this.content_div.classList.add('empty_cell');
    this.placeholder_text = document.createElement('div');
    this.placeholder_text.className = 'placeholder_text';
    this.placeholder_text.innerHTML = this.EMPTY_CELL_TEXT;
    this.content_div.appendChild(this.placeholder_text);
  }

  removePlaceholderText() {
    if (this.placeholder_text && this.placeholder_text.parentNode) {
      this.placeholder_text.parentNode.removeChild(this.placeholder_text);
      this.content_div.classList.remove('empty_cell');
    }
  }

  destroy() {
    $(this.wrapper_div).remove();
  }


  /**
   * Converts cell to stringified JSON
   * @returns {string}
   */

  toString() {
    const font = this.font ?
      `"font": {\
        ${this.font.family ? `"family": "${this.font.family.replace(new RegExp('"', 'g'), '\'')}",` : ''}
        "weight": "${this.font.weight}",\
        "color": "${this.font.color}"
      },` : '';

    return `{\
      "val": ${JSON.stringify(this.val)},
      "is_admin": ${this.has_text},\
      "style": {\
        "width": "${this.width}",
        "height": "${this.height}",\
        ${font}
        "backgroundColor": "${this.backgroundColor}", 
        "textAlign": "${this.justification}"\
      },\
      "colspan": ${this.span},
      "rowspan": ${this.rowspan},
      "position": ${this.position}\
    }`;
  }
}
