export default class QuestionDroppable {
  init() {
    this.setupListeners();
  }

  setupListeners = () => {
    $('.question-droppable').droppable({
      greedy: true,
      drop: (e, ui) => {
        const dropped = ui.draggable;
        const droppedOn = $(e.target);

        if (!droppedOn.hasClass('question-droppable')) return;

        const questionElement = dropped.find('.draggable');
        const previousQuestionGroupId = questionElement.data('question-group-id');
        const questionId = questionElement.data('question-id');
        const updateUrl = droppedOn.data('update-url');
        const questionGroupId = droppedOn.data('question-group-id');
        const parsedUrl = updateUrl.replace(':id', questionId);

        if (!questionId) return;

        const newQuestionElement = questionElement.clone();
        $(droppedOn.find('.question_group')).append(newQuestionElement.get(0));

        const questionData = window.questionData || {};
        const questionGroupElems = questionData[questionGroupId] || [];

        const $parent = questionElement.closest('li.question_group');

        questionGroupElems.push({
          currentElem: newQuestionElement,
          elemAfter: $parent.next(),
          elemBefore: $parent.prev(),
          previousElem: $parent,
          previousQuestionGroupId,
          questionId
        });

        const questionStateStorage = {
          [questionGroupId]: questionGroupElems
        };

        window.questionData = Object.assign({}, questionData, questionStateStorage);
        window.question_builder.questions[questionId].container = newQuestionElement[0];

        questionElement.remove();

        $.ajax({
          data: { question: { question_group_id: questionGroupId } },
          type: 'PATCH',
          url: parsedUrl,
        });
      }
    });

    $('.question-group-cancel').each((i, elem) => {
      $(elem).on('click', () => {
        const questionGroupId = $(elem).data('question-group-id');
        const updateUrl = $(elem).data('update-url');

        window.questionData[questionGroupId].forEach((questionData) => {
          if (questionData.elemBefore.hasClass('question_group')) {
            questionData.previousElem.insertAfter(questionData.elemBefore);
          }
          else {
            questionData.previousElem.insertBefore(questionData.elemAfter);
          }

          questionData.previousElem.find('[data-question-id]').show();
          questionData.currentElem.hide();
          const parsedUrl = updateUrl.replace(':id', questionData.questionId);

          $.ajax({
            data: { question: { question_group_id: questionData.previousQuestionGroupId }, reorder: true },
            type: 'PATCH',
            url: parsedUrl,
          });
        });

        const questionGroupIds = [...document.querySelectorAll('li.question_group')].map(node => (
          node.dataset.questionGroupId
        ));

        $.ajax({
          data: { question_group: questionGroupIds },
          type: 'POST',
          url: '/staffer/question_groups/reorder',
        });
      });
    });
  };
}
