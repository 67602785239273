define([], function() {
  function FileSizeValidation(config) {
    this.input = config.input_el;
    this.error_selector = config.error_selector;
    this.form_selector = config.form_selector;
    this.file_type = config.file_type;
  }

  FileSizeValidation.prototype = {
    init: function() {
      this._clearFileInput();
      this._buildListener();
    },

    _clearFileInput: function() {
      try {
        this.input.value = null;
      }
      catch (e) {
        // setting the input value to null doesn't work in IE versions before 11, so do this to clear the input instead
        this.input.parentNode.replaceChild(this.input.cloneNode(true), this.input);
      }
    },

    _buildListener: function() {
      if (window.File && window.FileReader && window.FileList && window.Blob) {
        $(this.input).on('click', function() {
          this.value = null;
        });
        $(this.input).on('change', this._handleFile.bind(this));
      }
    },

    _handleFile: function(e) {
      const file = e.target.files[0];
      const $form_container = $(e.target).closest(this.form_selector);
      const error_container = $form_container.find(this.error_selector).get(0);

      // max file size is 2MB
      if (file.size > 2097152) {
        error_container.innerText = `${this.file_type} must be less than 2MB. ${this.file_type} cannot be saved.`;
        if ($form_container !== undefined) {
          $form_container.find('input[type=submit]').attr('disabled', true);
          $form_container.find('button[type=submit]').attr('disabled', true);
        }
      }
      else {
        error_container.innerText = '';
        if ($form_container !== undefined) {
          $form_container.find('input[type=submit]').attr('disabled', false);
          $form_container.find('button[type=submit]').attr('disabled', false);
        }
      }
    }
  };

  return FileSizeValidation;
});
