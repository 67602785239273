define(['FillInTheBlankMarkupChecker.js'], function(FillInTheBlankMarkupChecker) {
  /*
    Form with Validation

    <form class="validate_form">
      <textarea class="validate_field">
      <input class="validate_field">
      <input class="validate_field" maxvalue="100">

      <button class="validate_submit">Save</button>
    </form>

    Requiring more than one input to be filled (e.g. multiple choice)

    <form class="validate_form">
      <textarea class="validate_field">
      <input class="validate_field validate_multiple_fields" data-fields="2">
      <input class="validate_field validate_multiple_fields" data-fields="2">

      <button class="validate_submit">Save</button>
    </form>
  */

  function ValidateForm(config) {
    this.form = config.form;
    this.validateFields = $(this.form).find('.validate_field');
    this.validateOnChangeFields = $(this.form).find('.validate_on_change');
    this.multiple_fields = $(this.form).find('.validate_multiple_fields');
    this.$submitButton = $(this.form).find('.validate_submit');
    this.$errorContainer = $(this.form).find('.form_error_container');
    this.valid = true;

    // used to validate minimum number of fields filled for multiple choice
    this.minimum_number_of_fields = 0;
  }

  ValidateForm.prototype = {
    init: function() {
      this._setupFormSubmit();
      this._setupListeners();
      return this;
    },

    _setupFormSubmit: function() {
      $(this.$submitButton).on('click', (e) => {
        this._validateForm(e);
      });

      $(this.form).on('keypress', (e) => {
        const enterKeyPressed = e.which === 13;
        if (enterKeyPressed) this._validateForm(e);
      });
    },

    _validateForm: function(e) {
      this.valid = true;

      if (this._validForm() !== true) {
        e.preventDefault();
        this.$submitButton.addClass('disabled');
        this._setupListeners();

        this.validateFields.each((_, field) => {
          if ($(field).hasClass('image_choice')) {
            field.addEventListener('validate_image_choice', () => {
              this._checkFieldValidity($(field));
            });
          }
        });
      }
    },

    _setupListeners: function() {
      this.validateFields.each(function() {
        this.addEventListener('focus', function() {
          if (['Enter Text', 'Choice 1', 'Choice 2'].includes(this.value)) this.select();
        });
      });

      // check all radio buttons and fields for MC
      if (this.multiple_fields.length > 0) {
        this._checkValidSelection();

        this.multiple_fields.each(function() {
          this.addEventListener('focus', function() {
            if (['Enter Text', 'Choice 1', 'Choice 2'].includes(this.value)) this.select();
          });
        });

        this.multiple_fields.each((_, field) => {
          $(field).on('input', () => this._checkFieldValidity($(field)));
        });
      }

      if (this.validateOnChangeFields.length > 0) {
        this.validateOnChangeFields.each((_, field) => {
          $(field).on('input', () => this._checkFieldValidity($(field)));
        });
      }

      // set invalid fields to validate on blur
      $(this.form).find('.invalid_field').each((_, field) => {
        $(field).on('input', () => this._checkFieldValidity($(field)));
      });
    },

    _validForm: function validateFormFields() {
      this._validateMultipleFields();

      this.validateFields.each((i, field) => this._checkFieldValidity($(field)));

      return this.valid;
    },

    _checkFieldValidity: function($field) {
      if ($field.hasClass('validate_multiple_fields')) {
        this._validateMultipleFields();
      }
      else if ($field.hasClass('validate_at_least_one_field')) {
        this._validateAtLeastOneField();
      }
      else {
        this._validateField($field);
      }
    },

    _validateAtLeastOneField: function() {
      this._validateAtLeastOneFieldForLocale('en');
      this._validateAtLeastOneFieldForLocale('es');
    },

    _validateAtLeastOneFieldForLocale: function(locale) {
      const $fields = $(this.form).find(`.validate_at_least_one_field.${locale}`);

      if (this._filledFields($fields).length > 0) {
        this._setFieldsAsValid($fields);
      }
      else {
        const errorMessage = "at least one can't be blank";

        $fields.each((_, field) => this._invalidField($(field), errorMessage));
      }
    },

    _validateMultipleFields: function() {
      this._validateMultipleFieldsForLocale('en');
      this._validateMultipleFieldsForLocale('es');
      if ($(this.form).find('.invalid_field').length === 0) {
        $(this.form).closest('li')[0].style.setProperty('border', 'none');
      }
    },

    // Assumes fields with `validate_multiple_fields` class also have a class of the locale string.
    _validateMultipleFieldsForLocale(locale = 'en') {
      const $fields = $(this.form).find(`.validate_multiple_fields.${locale}`);

      if ($fields.length < 1) {
        return;
      }

      this.minimum_number_of_fields = $fields.data('fields');

      this._setFieldsAsValid($fields);

      // Perform checkboxes validations:
      // Note: only English fields show the checkboxes currently.
      if (locale === 'en') {
        this._validateCheckboxFields($fields);
      }

      // Check that the minimum number of fields are filled:
      this._checkHasEnoughFilledFields($fields);

      // Check that all answer choices are unique
      this._checkDuplicates($fields);
    },

    _checkDuplicates: function($fields) {
      const values = $.map($fields, (field => field.value));

      // finds the index of the last answer choice field that is a duplicate
      const lastDuplicateIndex = values.reduce((acc, value, index) => {
        const isDuplicateValue = value === '' ? false : values.filter(v => v === value).length > 1;

        return isDuplicateValue ? index : acc;
      }, null);

      if (lastDuplicateIndex) {
        this._invalidField(
          $($fields[lastDuplicateIndex]),
          'All choices must be unique. Please try another one.'
        );
      }
    },

    _setFieldsAsValid: function($fields) {
      $fields.each((_, field) => {
        this._validField($(field));
      });
    },

    _validateCheckboxFields: function($fields) {
      const $checkedFields = this._checkedFields($fields);

      if ($checkedFields.length < 1) {
        this._setNoCheckedFieldsError($fields);
      }
      else {
        const $emptyCheckedFields = this._emptyFields($checkedFields);

        if ($emptyCheckedFields.length > 0) {
          this._setEmptyCheckedFieldsError($emptyCheckedFields);
        }
      }
    },

    _setNoCheckedFieldsError: function($fields) {
      // There must be at least one checkbox checked:
      const errorMessage = 'Please select at least one choice as correct.';

      // Always display error message on first field:
      const $firstField = $($fields.first());
      if ($firstField) this._invalidField($firstField, errorMessage);
    },

    _setEmptyCheckedFieldsError: function($errorFields) {
      $errorFields.each((_, field) => {
        // A checked field cannot be empty:
        const errorMessage = 'This field must contain text when the checkbox is selected';

        this._invalidField($(field), errorMessage);
      });
    },

    _checkedFields: function($fields) {
      return $fields.filter((_, field) => {
        const fieldCheckbox = $(field).data('radio');
        return $(fieldCheckbox).is(':checked');
      });
    },

    _emptyFields: function($fields) {
      return $fields.filter((_, field) => {
        if ($(field).hasClass('image_choice')) return !this._hasImage($(field));
        return $(field).val() === '';
      });
    },

    _filledFields: function($fields) {
      return $fields.filter((_, field) => {
        if ($(field).hasClass('image_choice')) return this._hasImage($(field));
        return $(field).val() !== '';
      });
    },

    _hasImage: function($field) {
      return $field.find('img').length > 0;
    },

    _checkHasEnoughFilledFields: function($fields) {
      // There must be at least `minimum_number_of_fields` fields filled:
      const filledFieldsCount = this._filledFields($fields).length;

      if (filledFieldsCount < this.minimum_number_of_fields) {
        this._setMinimumFilledFieldsError($fields, filledFieldsCount);
      }
    },

    _setMinimumFilledFieldsError: function($fields, filledFieldsCount) {
      // Only display errors on the first `minimum_number_of_fields` number of empty fields:
      const remainingFieldsToFillCount = this.minimum_number_of_fields - filledFieldsCount;
      const $errorFields = this._emptyFields($fields).slice(0, remainingFieldsToFillCount);

      $errorFields.each((_, field) => {
        let errorMessage;
        if ($(field).hasClass('image_choice')) {
          errorMessage = 'At least two choices must have an image added.';
        }
        else {
          errorMessage = `can't be blank - minimum of ${this.minimum_number_of_fields} fields need to be filled`;
        }

        this._invalidField($(field), errorMessage);
      });
    },

    _checkValidSelection: function() {
      this.multiple_fields.each((i, obj) => {
        $($(obj).data('radio')).on('change', () => this._validateMultipleFields());
      });
    },

    _validateField: function($field) {
      if (this._isNumberFieldWithRange($field) || this._isNumericalityField($field)) {
        this._validateFieldValueWithinBoundsAndNumericality($field);
      }
      else if ($field.val() !== '' && $field.hasClass('validate_fill_in_the_blank')) {
        this._validateFillInTheBlankFields();
      }
      else {
        ($field.val() !== '') ? this._validField($field) : this._invalidField($field);
      }
    },

    _validateFillInTheBlankFields: function() {
      // Assumes fields with `validate_fill_in_the_blank` class also have an id that ends in `es` or `en`.
      const $englishField = $(this.form).find('[id$="en"].validate_fill_in_the_blank');
      const $spanishField = $(this.form).find('[id$="es"].validate_fill_in_the_blank');
      const $autoTranslationEnabledField = $(this.form).find('#question_automatic_translation_enabled');

      const englishFieldErrors = this._validateFillInTheBlankField($englishField);
      const spanishFieldErrors = this._validateFillInTheBlankField($spanishField);

      // If there are no individual errors on the fields, check for translation sync errors:
      if (!englishFieldErrors && !spanishFieldErrors) {
        this._validateFillInTheBlankTranslations($englishField, $spanishField, $autoTranslationEnabledField);
      }
    },

    _validateFillInTheBlankField: function($field) {
      const fieldErrors = FillInTheBlankMarkupChecker.default.checkString($field.val());

      (!fieldErrors) ? this._validField($field) : this._invalidField($field, fieldErrors);

      return fieldErrors;
    },

    _validateFillInTheBlankTranslations: function($englishField, $spanishField, $autoTranslationEnabledField) {
      const translationErrors = !$autoTranslationEnabledField.is(':checked') &&
        FillInTheBlankMarkupChecker.default.checkTranslations($englishField.val(), $spanishField.val());

      if (translationErrors) {
        // Show the error on both the English field and the Spanish field:
        this._invalidField($englishField, translationErrors);
        this._invalidField($spanishField, translationErrors);
      }
      else {
        // Clear the errors on both the English field and the Spanish field:
        this._validField($englishField);
        this._validField($spanishField);
      }
    },

    _validateFieldValueWithinBoundsAndNumericality: function($field) {
      let [validBounds, validNumericality] = [true, true];
      if (this._isNumberFieldWithRange($field) && (
        !this._isWithinUpperBounds($field) || !this._isWithinLowerBounds($field)
      )) {
        validBounds = false;
        this._invalidField($field, this._rangeErrorMessage($field));
      }

      if (this._isNumericalityField($field) &&
          this._getNumericalityValue($field) === 'integer' &&
          $field.val().includes('.')
      ) {
        validNumericality = false;
        this._invalidField($field, this._numericalityErrorMessage($field));
      }

      if (validBounds && validNumericality) this._validField($field);
    },

    _isNumberFieldWithRange: function($field) {
      return $field.val() !== '' && (this._getMaxValue($field) !== null || this._getMinValue($field) !== null);
    },

    _isNumericalityField: function($field) {
      return $field.val() !== '' && this._getNumericalityValue($field) != null;
    },

    _getCurrentValueNumber: function($field) {
      return this._getIntegerValue($field.val());
    },

    _getMaxValue: function($field) {
      return this._getIntegerValue($field.data('maxvalue'));
    },

    _getMinValue: function($field) {
      return this._getIntegerValue($field.data('minvalue'));
    },

    _getNumericalityValue: function($field) {
      return $field.data('numericality');
    },

    _getIntegerValue: function(value) {
      if (value === undefined || value === '') {
        return null;
      }

      return parseInt(value, 10);
    },

    _isWithinUpperBounds: function($field) {
      if (this._getMaxValue($field) === null) {
        return true;
      }

      return this._getCurrentValueNumber($field) <= this._getMaxValue($field);
    },

    _isWithinLowerBounds: function($field) {
      if (this._getMinValue($field) === null) {
        return true;
      }

      return this._getCurrentValueNumber($field) >= this._getMinValue($field);
    },

    _validField: function($field) {
      if ($field.hasClass('invalid_field')) $field.removeClass('invalid_field');
      if (this.$submitButton.hasClass('disabled')) this.$submitButton.removeClass('disabled');
      if (this.$errorContainer) this.$errorContainer.next('p').remove();
      $field.next('p').remove();
    },

    _invalidField: function($field, errorMessage = null) {
      this.valid = false;
      $field.addClass('invalid_field');
      this._appendError($field, errorMessage);
    },

    _appendError: function($field, errorMessage = null) {
      if ($field.next().hasClass('invalid_field_error')) $field.next('p').remove();
      if (this.$errorContainer.next().hasClass('invalid_field_error')) this.$errorContainer.next('p').remove();

      if (errorMessage) {
        this._displaySpecificErrorMessage($field, errorMessage);
      }
      else {
        this._displayFieldErrorMessage($field);
      }

      this._expandInactiveForm();
    },

    _displaySpecificErrorMessage: function($field, errorMessage) {
      this.isShowingSpecificErrorMessage = true;
      const errorMessageTag = `<p class='invalid_field_error'>${errorMessage}</p>`;

      if (this.$errorContainer.length) {
        this.$errorContainer.after(errorMessageTag);
      }
      else {
        $field.after(errorMessageTag);
      }
    },

    _expandInactiveForm: function() {
      const $form = $(this.form);
      $form.closest('li').find('h3:not(.active)').click();
      $form.closest('li')[0].style.setProperty('border', '1px solid red', 'important');
    },

    _rangeErrorMessage: function($field) {
      let minValue = 1;

      if (this._getMinValue($field) !== null) {
        minValue = this._getMinValue($field);
      }

      return `Value needs to be between ${minValue} and ${this._getMaxValue($field)}`;
    },

    _numericalityErrorMessage: function($field) {
      const fieldName = $field.data('name');
      const message = (fieldName != null ? `${fieldName} ` : '') + 'must be a whole number';

      return message[0].toUpperCase() + message.slice(1).toLowerCase();
    },

    _displayFieldErrorMessage: function($field) {
      if (this.isShowingSpecificErrorMessage) {
        return;
      }

      if ($field.val() === '') {
        $field.after("<p class='invalid_field_error' data-error='blank'>Text field can't be blank.</p>");
      }
    }
  };

  return ValidateForm;
});
